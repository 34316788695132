import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { contactSection } from "../../content/Common"
import ContentSectionWithImage from "../../components/common/ContentSectionWithImage"
import ContactSection from "../../components/common/ContactSection"
import InnerHeader from "../../components/molecules/InnerHeader"
import HouseDetailsSection from "../../components/common/HouseDetailsSection"
import WhatWeDoSection from "../../components/pages/Services/WhatWeDoSection"
import CTASectionHorizontal from "../../components/molecules/CTASectionHorizontal"
import FaqsSection from "../../components/common/FaqsSection"
import { propertyEnrollmentPage } from "../../content/Services"

const PropertyEnrollment = () => (
  <Layout>
    <Seo title="Property Enrollment" />

    <InnerHeader
      title={propertyEnrollmentPage.title}
      googleReviewText={propertyEnrollmentPage.googleReviewText}
      description={propertyEnrollmentPage.description}
    />

    <ContentSectionWithImage
      componentEC={`md:!py-0 !pb-0`}
      data={
        propertyEnrollmentPage.enrolmentWithCareCasaForPropertyManagementSection
      }
    />
    <ContentSectionWithImage
      componentEC={`!flex-col-reverse md:!flex-row-reverse`}
      data={propertyEnrollmentPage.ourSDAPropertyEnrolmentProcessSection}
    />

    <WhatWeDoSection data={propertyEnrollmentPage.benefitsSection} />

    <HouseDetailsSection
      data={propertyEnrollmentPage.whyChooseCarecasaSection}
    />

    <CTASectionHorizontal data={propertyEnrollmentPage.ctaSection} />

    <FaqsSection faqs={propertyEnrollmentPage.faqs} />

    <ContactSection data={contactSection} sectionTitleAlignment={"center"} />
  </Layout>
)

export default PropertyEnrollment
